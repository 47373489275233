<template>
  <SmartLink
    v-if="settings"
    :nick="downloadNick"
    :soft="software || softwareTitle"
    :to="to"
    :href="href"
    :class="linkClass"
  >
    <ImagePicture
      v-if="props.nid && settings"
      :alt="props.alt"
      :file-name="src"
      :sources="sources"
      :width="settings.width"
      :height="settings.height"
      :src-err="srcErr"
      :class="props.class"
    >
    </ImagePicture>
  </SmartLink>
</template>
<script setup>
defineOptions({ inheritAttrs: false });
const props = defineProps({
  nid: { type: [Number, String], required: true, default: 0 },
  contentType: {
    type: String,
    required: true,
    default: "",
  },
  alt: { type: String, default: "" },
  downloadNick: { type: String, default: "" },
  software: { type: String, default: "" },
  softwareTitle: { type: String, default: "" },
  to: { type: String, default: "" },
  href: { type: String, default: "" },
  linkClass: { type: String, default: "" },
  imgClass: { type: String, default: "" },
  class: { type: String, default: "" },
});
// const runtimeConfig = useRuntimeConfig();
// const page = inject("page");

// const hostname = unref(page).hostname; // || runtimeConfig.public.hostname;
// const prefix = process.env.NODE_ENV === "development" ? `//${hostname}` : "";
const { contentType, nid } = toRefs(props);
const settings = contentType.value.includes("Slots")
  ? {
      dir: "slots-review",
      url: "reels-246x185",
      ext: "jpg",
      width: 246,
      height: 185,
    }
  : contentType.value.includes("Brand")
  ? {
      dir: "brand-review",
      url: "logo-157x75",
      ext: "png",
      width: 157,
      height: 75,
      default: `/files_default/brand-review/logo-157x75.png`,
    }
  : contentType.value.includes("Software")
  ? {
      dir: "software-review",
      url: "logo-157x75",
      ext: "png",
      width: 157,
      height: 75,
      default: `/files_default/software-review/logo-157x75.png`,
    }
  : null;
const path = settings
  ? `/files_default/${settings.dir}/${String(nid.value).split("").join("/")}/${settings.url}.${settings.ext}`
  : "";
const src = path;
const srcErr = settings ? `/files_default/${settings.dir}/${settings.url}.${settings.ext}` : "";
const avif = settings ? { url: src.replace(settings.ext, "avif"), type: "image/avif" } : "";
const webp = settings ? { url: src.replace(settings.ext, "webp"), type: "image/webp" } : "";
const sources = [avif, webp].filter(e => e);
</script>
